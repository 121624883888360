<template>
  <div>
    <loading v-if="is_loading" />
    <content-not-view
      v-else-if="
        (subPermission.store == false && !isEdit) ||
        (subPermission.update == false && isEdit)
      "
    />
    <b-card v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="4">
              <b-form-group :label="$t('g.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.name')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Name -->
            <!-- is_available -->
            <b-col md="4" v-if="isEdit">
              <b-form-group :label="$t('g.language')" label-for="Language">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.language')"
                  rules="required"
                >
                  <select class="form-control" v-model="form_data.lang">
                    <option selected value>
                      {{ $t("g.Please_Select_Language") }}
                    </option>
                    <option
                      v-for="(lang, index) in lang_options"
                      :value="lang.value"
                      :key="index"
                    >
                      {{ lang.text }}
                    </option>
                  </select>
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.language')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** is_available -->
            <b-col md="6 ">
              <b-form-group
                :label="
                  $t(
                    'g.report-reasons/listOfreportReason/inputs/lablel/IsAvailableReportReason'
                  )
                "
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(
                    'g.report-reasons/listOfreportReason/inputs/lablel/IsAvailableReportReason'
                  )"
                  rules="required"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="form_data.is_available"
                      name="some-radio9"
                      value="1"
                      class="custom-control-success"
                    >
                      {{ $t("g.yes") }}</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form_data.is_available"
                      name="some-radio9"
                      value="0"
                      class="custom-control-danger mr-1 ml-1"
                    >
                      {{ $t("g.no") }}</b-form-checkbox
                    >
                  </div>
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0], $t(
                          'g.report-reasons/listOfreportReason/inputs/lablel/IsAvailableReportReason'
                      )) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                :disabled="isButtonDisabled"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import helper from "@/composables/helper.js";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  name: "AddEducationLevel",
  data() {
    return {
      form_data: {
        name: null,
        is_available: "1",
        lang: null,
      },
      isEdit: false,
      currentId: null,
      lang_options: [
        { value: "ar", text: this.$t("g.Arabic") },
        { value: "en", text: this.$t("g.English") },
      ],
      is_loading: true,
      archiveData: null,
      isButtonDisabled: false,
    };
  },
  components: {
    contentNotView,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BFormFile,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    loading,
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getdata();
    } else {
      this.is_loading = false;
    }
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler(newval, oldVal) {
        if (newval) {
          this.isEdit = true;
          this.currentId = newval;
          this.getdata();
        } else {
          this.form_data.name = null;
          this.isEdit = false;
        }
      },
    },
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "report-reasons");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    validationForm() {
      this.$refs.form.validate().then((succes) => {
        if (succes) {
          !this.isEdit ? this.submitAdd() : this.submitEdit();
        }
      });
    },
    submitAdd() {
      this.isButtonDisabled = true;

      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      formData.delete("lang");
      this.$http
        .post("admin/report-reasons", formData)
        .then((response) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "ReportReasonsList" }, 400);
          });
        })
        .catch((error) => {
          this.isButtonDisabled = false;

          this.makeToast("danger", error.message, this.$t("g.send.errorTitle"));
        });
    },
    submitEdit() {
      this.isButtonDisabled = true;
      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      if (this.archiveData["name"] === this.form_data["name"]) {
        formData.delete("name");
      }
      this.$http
        .post(`admin/report-reasons/${this.currentId}?_method=put`, formData)
        .then((response) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "ReportReasonsList" }, 400);
          });
        })
        .catch((error) => {
          this.isButtonDisabled = false;
          this.makeToast("danger", error.message, this.$t("g.send.errorTitle"));
        });
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    getdata() {
      this.$http
        .get(`admin/report-reasons/${this.currentId}`)
        .then((response) => {
          this.archiveData = response.data.data;
          for (let item in this.form_data) {
            this.form_data[item] = response.data.data[item];
            if ((item = "is_available")) {
              this.form_data[item] = response.data.data[item] ? "1" : "0";
            }
          }
          this.$store.dispatch("GET_PERMISSION", "report-reasons");
          this.subPermission = this.$store.state.userData.sub_Permission;
          this.is_loading = false;
        })
        .catch((error) => {
          this.makeToast("danger", error.message, this.$t("g.send.errorTitle"));
        });
    },
  },
};
</script>
